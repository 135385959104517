import axios from 'axios'
import Cookies from 'js-cookie'
import { ADMIN_AUTH_PREFIX_PATH } from '../AppConfig'

const token = Cookies.get('SMARTCAF_JWT_TOKEN')
const language = Cookies.get('i18next')

const url = process.env.REACT_APP_HOST

const axiosInstance = axios.create({
  baseURL: `${url}api`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: token,
    lang: language,
  },
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      Cookies.remove('SMARTCAF_JWT_TOKEN', { path: '/' })
      window.location.href = `${ADMIN_AUTH_PREFIX_PATH}`
    }
    return Promise.reject(error)
  }
)

export const axiosFormData = axios.create({
  baseURL: `${url}api`,
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: token,
    lang: language,
  },
})

axiosFormData.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      Cookies.remove('SMARTCAF_JWT_TOKEN', { path: '/' })
      window.location.href = `${ADMIN_AUTH_PREFIX_PATH}`
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
