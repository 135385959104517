import { useState } from 'react'
import { Dropdown, Avatar } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'

import { ADMIN_AUTH_PREFIX_PATH } from '../../configs/AppConfig'
import NavItem from '../shared-components/NavItem'
import { UserOutlined } from '@ant-design/icons'
import { ReactComponent as Chevron } from '../shared-components/svgs/chevron.svg'
import { ReactComponent as Profile } from '../shared-components/svgs/profile.svg'
import { ReactComponent as Logout } from '../shared-components/svgs/logout.svg'

const NavProfile = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { profile } = useSelector((state) => state.myProfile)

  const [open, setOpen] = useState(false)

  const items = [
    {
      key: '1',
      label: (
        <NavItem
          title={t('profile.title')}
          icon={<Profile className='w-24' />}
          onClick={() => {
            history.push(`/admin/dashboard/Profile`)
            setOpen(false)
          }}
        />
      ),
    },
    {
      key: '2',
      label: (
        <NavItem
          title={t('sidenav.logout')}
          icon={<Logout className='w-24' />}
          onClick={() => {
            Cookies.remove('SMARTCAF_JWT_TOKEN', { path: '/' })
            history.push(`${ADMIN_AUTH_PREFIX_PATH}`)
            window.location.reload()
          }}
        />
      ),
    },
  ]

  return (
    <Dropdown
      trigger={['click']}
      onOpenChange={(open) => setOpen(open)}
      menu={{
        items,
      }}
    >
      <div className='d-flex align-items-center transition-all cursor-pointer'>
        {profile?.profile_photo ? (
          <div
            className='bg-cover w-40 h-40 rounded-circle'
            style={{
              backgroundImage: `url(${process.env.REACT_APP_IMG_PATH}${profile?.profile_photo})`,
            }}
          ></div>
        ) : (
          <Avatar shape='circle' size='default' icon={<UserOutlined />} />
        )}

        <p className='fs-14 mx-2'>
          {profile?.name} {profile?.lname}
        </p>

        <Chevron
          className={`${open ? 'rotate-180' : ''} transition-all w-16`}
        />
      </div>
    </Dropdown>
  )
}

export default NavProfile
