import { useState, useEffect } from 'react'
import { Menu } from 'antd'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import navTree from '../../configs/NavConfig/Admin'
import { ADMIN_AUTH_PREFIX_PATH } from '../../configs/AppConfig'

import { ReactComponent as Logout } from '../shared-components/svgs/sider/logout.svg'

const { SubMenu } = Menu

export default function MenuContent({ setNavOpen }) {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const { profile, hasFullPermissions, permissions } = useSelector(
    (state) => state.myProfile
  )

  const [autherizedLinks, setAutherizedLinks] = useState([])

  useEffect(() => {
    setAutherizedLinks([])
    if (profile) {
      navTree.forEach((link) => {
        if (link.role) {
          const hasPermission = permissions.some(
            (permission) => permission.name === link.role
          )

          if (hasPermission)
            setAutherizedLinks((autherizedLinks) => [...autherizedLinks, link])
        } else {
          let submenu = []

          link.submenu.forEach((subLink) => {
            const hasPermission = permissions.some(
              (permission) => permission.name === subLink.role
            )

            if (hasPermission) submenu.push(subLink)
          })

          if (submenu.length > 0) {
            const menu = { ...link, submenu }
            setAutherizedLinks((autherizedLinks) => [...autherizedLinks, menu])
          }
        }
      })
    }
  }, [profile, hasFullPermissions, permissions])

  return (
    <Menu mode='inline'>
      {/* NAVLINKS */}
      {autherizedLinks &&
        autherizedLinks.map((menu) =>
          menu.submenu.length > 0 ? (
            <SubMenu
              key={menu.key}
              icon={
                location.pathname.split('/')[3] === menu.path.split('/')[3]
                  ? menu.iconActive
                  : menu.icon
              }
              title={t(menu.title)}
            >
              {menu.submenu.map((subMenu) => (
                <Menu.Item
                  key={subMenu.key}
                  className={`${
                    location.pathname.split('/')[4] ===
                      subMenu.path.split('/')[4] &&
                    location.pathname.split('/')[3] ===
                      subMenu.path.split('/')[3]
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => setNavOpen(false)}
                >
                  {t(subMenu.title)}
                  <Link to={subMenu.path} />
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              key={menu.key}
              icon={
                location.pathname.split('/')[3] === menu.path.split('/')[3]
                  ? menu.iconActive
                  : menu.icon
              }
              className={`${
                location.pathname.split('/')[3] === menu.path.split('/')[3]
                  ? 'active'
                  : ''
              }`}
              onClick={() => setNavOpen(false)}
            >
              {t(menu.title)}
              <Link to={menu.path} />
            </Menu.Item>
          )
        )}

      {/* LOGOUT */}
      <Menu.Item
        key='logout'
        icon={<Logout />}
        className='logout'
        onClick={() => {
          Cookies.remove('SMARTCAF_JWT_TOKEN', { path: '/' })
          history.push(`${ADMIN_AUTH_PREFIX_PATH}`)
          window.location.reload()
        }}
      >
        {t('sidenav.logout')}
      </Menu.Item>
    </Menu>
  )
}
